<template>
  <a :href="url" :target="target" :class="['social-icon', class_names]">
    <base-icon :icon-name="type" :width="icon_size" :height="icon_size" :viewbox="viewBoxValues[type] || '0 0 40 40'">
      <icon-youtube v-if="type === 'youtube'"></icon-youtube>
      <icon-instagram v-if="type === 'instagram'"></icon-instagram>
      <icon-twitter v-if="type === 'twitter'"></icon-twitter>
      <icon-linkedin v-if="type === 'linkedin'"></icon-linkedin>
      <icon-facebook v-if="type === 'facebook'"></icon-facebook>
    </base-icon>
  </a>
</template>

<script>
export default {
  data: function() {
    return {
      viewBoxValues: {
        youtube: "0 0 39.1 38.8",
        instagram: "0 0 39.5 39.6",
        twitter: "0 0 39.5 39.4",
        linkedin: "0 0 35 35",
        facebook: "0 0 39.5 39.4"
      }
    };
  },
  props: ["url", "type", "class_names", "icon_size", "target"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconYoutube: () => import(/* webpackChunkName: "icon-youtube" */ "../icons/IconYoutube.vue"),
    IconInstagram: () => import(/* webpackChunkName: "icon-instagram" */ "../icons/IconInstagram.vue"),
    IconTwitter: () => import(/* webpackChunkName: "icon-twitter" */ "../icons/IconTwitter.vue"),
    IconLinkedin: () => import(/* webpackChunkName: "icon-linkedin" */ "../icons/IconLinkedin.vue"),
    IconFacebook: () => import(/* webpackChunkName: "icon-facebook" */ "../icons/IconFacebook.vue")
  }
};
</script>
